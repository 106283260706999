import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS, osName } from 'react-device-detect';
import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import classNames from 'classnames';
import Link from 'next/link';
import style from './DownloadButtons.module.scss';
import { nextAsset } from './nextAsset';

export const { downloadMacOS, downloadWindows, downloadLinux, downloadIOS, downloadAndroid } = defineMessages({
  downloadMacOS: {
    id: 'web.download_buttons.download.macOS',
    defaultMessage: 'Download for macOS'
  },
  downloadWindows: {
    id: 'web.download_buttons.download.windows',
    defaultMessage: 'Download for Windows'
  },
  downloadLinux: {
    id: 'web.download_buttons.download.linux',
    defaultMessage: 'Download for Linux'
  },
  downloadAndroid: {
    id: 'web.download_buttons.download.android',
    defaultMessage: 'Download Quizado for Android'
  },
  downloadIOS: {
    id: 'web.download_buttons.download.ios',
    defaultMessage: 'Download Quizado for iOS'
  }
});

interface AvailableVersionButton {
  to: string;
  onClick?: (e: React.MouseEvent) => void;
  name: string;
  image: string;
  isMobile: boolean;
  alt: string;
}

export function useAvailableDownloads(): {
  androidVersion: AvailableVersionButton;
  iOSVersion: AvailableVersionButton;
  linuxVersion: AvailableVersionButton;
  macOSVersion: AvailableVersionButton;
  windowsVersion: AvailableVersionButton;
  allAvailableVersions: AvailableVersionButton[];
} {
  const intl = useIntl();
  const { locale } = useRouter();

  const androidVersion = useMemo(
    () => ({
      to: '/download?platform=android',
      name: 'Android',
      image: nextAsset(`images/download/download-googleplay-${locale}.svg`),
      isMobile: true,
      alt: intl.formatMessage(downloadAndroid)
    }),
    [intl, locale]
  );
  const iOSVersion = useMemo(
    () => ({
      to: '/download?platform=ios',
      name: 'iOS',
      image: nextAsset(`images/download/download-appstore-${locale}-light.svg`),
      isMobile: true,
      alt: intl.formatMessage(downloadIOS)
    }),
    [intl, locale]
  );
  const macOSVersion: AvailableVersionButton = useMemo(
    () => ({
      to: '/download?platform=macos',
      name: 'macOS',
      image: style.macosDownload,
      isMobile: false,
      alt: intl.formatMessage(downloadMacOS)
    }),
    [intl]
  );
  const windowsVersion = useMemo(
    () => ({
      to: '/download?platform=windows',
      name: 'Windows',
      image: style.windowsDownload,
      isMobile: false,
      alt: intl.formatMessage(downloadWindows)
    }),
    [intl]
  );
  const linuxVersion = useMemo(
    () => ({
      to: '/download?platform=linux',
      name: 'Linux',
      image: style.linuxDownload,
      isMobile: false,
      alt: intl.formatMessage(downloadLinux)
    }),
    [intl]
  );

  const allAvailableVersions: AvailableVersionButton[] = [
    macOSVersion,
    windowsVersion,
    linuxVersion,
    iOSVersion,
    androidVersion
  ];

  return { androidVersion, iOSVersion, linuxVersion, macOSVersion, windowsVersion, allAvailableVersions };
}

export const DownloadButtons: React.FC<{ onDarkBg?: boolean }> = React.memo(({ onDarkBg }) => {
  const intl = useIntl();
  const { locale } = useRouter();
  const [macOSPickerShown, setMacOSPickerShown] = useState<boolean>(false);

  const onMacOSClicked = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setMacOSPickerShown(true);
  }, []);
  const macOSModalToggle = useCallback(() => {
    setMacOSPickerShown(false);
  }, []);
  const { androidVersion, iOSVersion, linuxVersion, macOSVersion, windowsVersion, allAvailableVersions } =
    useAvailableDownloads();
  const [showToUserVersion, setShowToUserVersion] = useState<{
    to: string;
    name: string;
    image: string;
    isMobile: boolean;
    alt: string;
  }>(iOSVersion);

  const availableVersions: AvailableVersionButton[] = allAvailableVersions.filter(
    (v) => v.name !== showToUserVersion?.name
  );

  useEffect(() => {
    if (isAndroid) {
      setShowToUserVersion(androidVersion);
    } else if (isIOS) {
      setShowToUserVersion(iOSVersion);
    } else if (osName === 'Windows') {
      setShowToUserVersion(windowsVersion);
    } else if (osName === 'Mac OS') {
      setShowToUserVersion(macOSVersion);
    } else {
      setShowToUserVersion(linuxVersion);
    }
  }, [androidVersion, iOSVersion, linuxVersion, macOSVersion, windowsVersion]);

  return (
    <>
      <Row
        className={classNames(
          'pt-4 justify-content-center d-none d-xl-flex align-items-center',
          style.downloadButtons,
          { onDarkBg }
        )}
      >
        <Col className="text-center">
          <Button
            color="secondary"
            block
            className={classNames(
              'mb-3 mr-2 secondary text-primary text-heading py-3 btn-mobile-border',
              style.downloadButton,
              style.macosDownload
            )}
            onClick={onMacOSClicked}
          >
            {intl.formatMessage(downloadMacOS)}
          </Button>{' '}
          <Link prefetch={false} href="/download?platform=windows" passHref>
            <Button
              color="secondary"
              block
              className={classNames(
                'mb-3 mr-2 secondary text-primary text-heading py-3 btn-mobile-border',
                style.downloadButton,
                style.windowsDownload
              )}
            >
              {intl.formatMessage(downloadWindows)}
            </Button>
          </Link>
          <Link prefetch={false} href="/download?platform=linux" passHref>
            <Button
              color="secondary"
              block
              className={classNames(
                'mb-3 mr-2 secondary text-primary text-heading py-3 btn-mobile-border',
                style.downloadButton,
                style.linuxDownload
              )}
            >
              {intl.formatMessage(downloadLinux)}
            </Button>
          </Link>
        </Col>
        <Col>
          <Row>
            <Col className="text-center">
              <Link prefetch={false} href="/download?platform=ios" passHref>
                <img
                  src={nextAsset(`images/download/download-appstore-${locale}-light.svg`)}
                  className={classNames('img-fluid', style.downloadNativeStoreImage)}
                  alt={intl.formatMessage(downloadIOS)}
                />
              </Link>{' '}
              <Link prefetch={false} href="/download?platform=android" passHref>
                <img
                  src={nextAsset(`images/download/download-googleplay-${locale}.svg`)}
                  className={classNames('img-fluid', style.downloadNativeStoreImage)}
                  alt={intl.formatMessage(downloadAndroid)}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <div
        className={classNames('pt-4 justify-content-center d-xl-none text-center mx-auto', style.downloadButtons, {
          [style.onDarkBg]: onDarkBg
        })}
      >
        <div>
          <Link prefetch={false} href={showToUserVersion.to} passHref>
            {showToUserVersion.isMobile ? (
              <img
                src={showToUserVersion.image}
                className={classNames('img-fluid', style.downloadNativeStoreImage)}
                alt={showToUserVersion.alt}
              />
            ) : (
              <Button
                color="secondary"
                block
                className={classNames(
                  'mb-3 mr-2 secondary text-primary text-heading py-3 btn-mobile-border',
                  style.downloadButton,
                  showToUserVersion.image
                )}
              >
                {showToUserVersion.alt}
              </Button>
            )}
          </Link>
          <p className="pt-1 opacity-50">
            <FormattedMessage id="web.download_buttons.also_on" defaultMessage="We're also available on" />
          </p>
          <p
            className={classNames('font-weight-bold text-center d-flex px-3 flex-wrap justify-content-center', {
              [style.onDarkBgAlsoOn]: onDarkBg
            })}
          >
            {availableVersions.map((v) => (
              <>
                <Link prefetch={false} href={v.to} passHref onClick={v.onClick} className="text-primary">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}

                  {v.name}
                </Link>{' '}
              </>
            ))}
          </p>
        </div>
      </div>

      <Modal isOpen={macOSPickerShown} toggle={macOSModalToggle} centered>
        <ModalBody className="px-4">
          <h3 className="text-center pt-4 pb-3">
            {intl.formatMessage({
              defaultMessage: 'Select the version that’s right for your Mac',
              id: 'web.macos-version-picker.title'
            })}
          </h3>
          <Row className="py-3">
            <Col className="text-center">
              <Link prefetch={false} href="/download?platform=macos" passHref>
                <Button block color="secondary" size="lg">
                  {intl.formatMessage({
                    defaultMessage: 'Mac with Intel chip',
                    id: 'web.macos-version-picker.button.intel'
                  })}
                </Button>
              </Link>
              <small>
                {intl.formatMessage({
                  defaultMessage: 'MOST COMMON',
                  id: 'web.macos-version-picker.most-common'
                })}
              </small>
            </Col>
            <Col>
              <Link prefetch={false} href="/download?platform=macosarm" passHref>
                <Button block color="light" size="lg">
                  {intl.formatMessage({
                    defaultMessage: 'Mac with Apple chip',
                    id: 'web.macos-version-picker.button.apple'
                  })}
                </Button>
              </Link>
            </Col>
          </Row>
          <Card className="my-5" color="light">
            <CardBody>
              <CardTitle tag="h5" className="text-white">
                {intl.formatMessage({
                  defaultMessage: 'Check which chip your Mac has',
                  id: 'web.macos-version-picker.check.title'
                })}
              </CardTitle>
              <ol className="px-3 text-white">
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'At the top left, open the Apple menu.',
                    id: 'web.macos-version-picker.check.step1'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Select About This Mac.',
                    id: 'web.macos-version-picker.check.step2'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'In the “Overview” tab, look for “Processor” or “Chip”.',
                    id: 'web.macos-version-picker.check.step3'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Check if it says “Intel” or “Apple”.',
                    id: 'web.macos-version-picker.check.step4'
                  })}
                </li>
              </ol>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
});
